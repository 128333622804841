module.exports = {

     baseUrl : "https://seagoldapi.wealwin.com",

     tradecontract       : "0x4876ce51ff158016FcCb5F30333c07cFB6A415F2",

     presalecontract	 : "0xebed8f7b035429c10f2299bcd19e2f1d9fc847d8",

     Recaptchakey : "6LeQQBgnAAAAACW_QSY6PzqWFsUSwg_09QEq9T3H",

     network:"5",
     frontUrl: "https://seagold-frontend.pages.dev/",  //demo

     API_URL : "https://seagoldapi.wealwin.com"   //demo

}